<template>
  <div class="container">
    <div class="row text-center my-5" v-if="loading">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div v-else>
      <div class="row mb-3">
        <div class="col-md-8 mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <div v-if="clientIsSubscribed">
                <div v-if="clientSubscription.payment_status === 'paid'">
                  <h5 class="mb-3">{{ schedule.title }}</h5>
                  <div v-for="item in schedule.schedule_items">
                    <div class="card shadow-sm cursor-pointer mb-3" @click="viewScheduleItem(item)">
                      <div class="card-body">
                        <p>{{item.item.title}}</p>
                        <p>{{item.item.label}}</p>
                        <p v-if="isItemAvailable(item)" class="badge badge-primary">Available Now</p>
                        <p v-else class="badge badge-light">Available from {{item.available_from | formatDate}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row">
                    <div class="col text-center">
                      <i class="fad fa-info-circle fa-3x text-primary"></i>
                      <p class="my-3">Payment is due before this content can be unlocked.</p>
                      <button class="btn btn-primary" @click="viewOrder">
                        Make Payment
                      </button>
                    </div>
                  </div>
                </div>

              </div>
              <div v-else>
                <div class="card h-100 cursor-pointer">
                  <img v-if="schedule.online_programme.cover_image"
                       :src="schedule.online_programme.cover_image"
                       class="card-img-top mx-auto"
                       style="height: auto; width: 100%; max-height: 250px; max-width:250px;">
                  <img v-else
                       :src="user.clinic.logo"
                       class="card-img-top mx-auto p-4"
                       style="height: auto; width: 100%; max-height: 250px; max-width:250px;">
                  <div class="card-body text-center">
                    <h5 class="mb-2">{{schedule.online_programme.title}}</h5>
                    <h5 class="mb-2">{{schedule.start_date | formatDate}}</h5>
                    <h5><b>£{{schedule.online_programme.price.toFixed(2)}}</b></h5>
                    <hr class="my-2" />
                    <p class="text-left">{{schedule.online_programme.description}}</p>
                    <button class="btn btn-primary" @click="showCardDetails" v-if="!showCardDetailsCard">
                      Subscribe
                    </button>

                    <div :class="!showCardDetailsCard ? 'd-none' : ''">
                      <div :class="!loadingCard ? 'd-none' : ''">
                        <i class="fad fa-spin fa-spinner fa-3x mt-3 text-primary mx-auto"></i>
                      </div>
                      <div :class="loadingCard ? 'd-none' : ''">
                        <form role="form">
                          <!-- Card Details -->
                          <div class="row mb-4">
                            <div class="col mx-auto">
                              <label for="name">Card Details</label>
                              <div id="payment-card-element"></div>
                            </div>
                          </div>
                          <!-- Payment Button -->
                          <div class="row mb-0">
                            <div class="col mx-auto text-center">
                              <button type="submit" class="btn btn-primary" @click.prevent="processPayment" :disabled="busyMakingPayment">
                              <span v-if="busyMakingPayment">
                                <i class="fa fa-btn fa-spinner fa-spin mr-1"></i>Processing Payment
                              </span>
                                <span v-else>Make Card Payment</span>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      loadingSubscription: true,
      loadingSchedule: true,
      loadingPaymentIntent: true,
      loadingCard: false,
      busyMakingPayment: false,
      schedule: null,
      showCardDetailsCard: false,
      stripe: null,
      paymentIntent: null,
      clientSubscription: null,
    };
  },
  methods: {
    fetchClientSchedule() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/client/online-programmes/fetch-online-programme",
          {
            client_id: this.user.id,
            online_programme_schedule_id: this.$route.params.id
          }
      ).then(({ data }) => {
        this.schedule = data;
        this.loadingSchedule = false;
      });
    },
    fetchClientSubscription() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/client/online-programmes/fetch-online-programme-client-subscription",
          {
            client_id: this.user.id,
            online_programme_schedule_id: this.$route.params.id
          }
      ).then(({ data }) => {
        this.clientSubscription = data;
        this.loadingSubscription = false;
      });
    },
    viewScheduleItem(item) {
      this.$router.push('/online-programme-items/' + item.id);
    },
    isItemAvailable(item) {
      return moment(item.available_from).isBefore();
    },
    showCardDetails() {
      this.showCardDetailsCard = true;
      this.loadingCard = false;
      this.initStripe();
    },
    processPayment() {
      this.busyMakingPayment = true;
      this.stripe.confirmCardPayment(this.paymentIntent.client_secret, {
        payment_method: { card: this.cardElement },
      }).then((result) => {
        if (result.error) {
          console.log(result.error);
          this.$EventBus.$emit("alert", {
            color: "danger",
            message: result.error.message,
          });
          self.hasCardErrors = true;
          this.busyMakingPayment = false;
          return;
        }
        this.$EventBus.$emit("alert", { message: "Payment Successful! Thank you." });
        const vm = this;
        setTimeout(() => {
          vm.$router.push("/orders");
        }, 2000);
        this.busy = false;
      });
    },
    fetchPaymentIntent() {
      return this.$axios.post(process.env.VUE_APP_API_URL + "/client/online-programmes/generate-online-programme-payment-intent",
          {
            client_id: this.user.id,
            online_programme_schedule_id: this.$route.params.id
          }
      ).then(({ data }) => {
        this.paymentIntent = data;
        this.loadingPaymentIntent = false;
      });
    },
    createCardElement(container) {
      if (!this.stripe) {
        throw "Invalid Stripe Key/Secret";
      }

      var card = this.stripe.elements().create("card", {
        style: {
          base: {
            "::placeholder": {
              color: "#aab7c4",
            },
            fontFamily:
                'Whitney, Lato, -apple-system, BlinkMacSystemFont,"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji","Segoe UI Emoji", "Segoe UI Symbol"',
            color: "#495057",
            fontSize: "15px",
          },
        },
      });

      card.mount(container);
      return card;
    },
    initStripe() {
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
      this.cardElement = this.createCardElement("#payment-card-element");

      this.fetchPaymentIntent().then(() => {
        this.loadingCard = false;
      })
    },
    viewOrder() {
      this.$router.push('/orders/' + this.clientSubscription.order_id + '/card-payment');
    }
  },
  computed: {
    loading() {
      return this.loadingSchedule || this.loadingSubscription;
    },
    user() {
      return this.$store.user;
    },
    clientIsSubscribed() {
      return this.schedule && this.schedule.subscribed_client_ids.includes(this.user.id);
    }
  },
  mounted() {
    this.fetchClientSchedule();
    this.fetchClientSubscription();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL [@] HH:mm A");
    },
  }
};
</script>

<style>
</style>
